<template>
  <div class="graph-container">
    <div class="graph-hd">
      <input v-model="info.name" placeholder="请输入思维导图名称" />
      <div class="flex">
        <!-- <t-btn @click="saveImage" class="mr10" w="140px" h="28px">保存并导出图片</t-btn> -->
        <t-btn @click="save" w="80px" h="28px">保存</t-btn>
      </div>
    </div>
    <iframe class="iframe" name="graphIframe" src="/drawio/index.html" />
  </div>
</template>
<script>
import message from '@/mixins/message'

const initParams = () => ({
  id: '',
  name: ''
}) 
export default {
  mixins: [message],
  data() {
    return {
      info: initParams()
    }
  },
  methods: {
    // 获取思维导图信息
    getXmlInfo() {
      const { id = null } = this.$route.query

      if (!id) return

      this.$api.cases.getXmlInfo({ id }).then(res => {
        Object.keys(this.info).forEach(v => {
          this.info[v] = res[v] || ''
        })
        graphIframe.window._revealXml(res.content)
      })
    },
    // 保存
    save() {
      const { info: { name } } = this

      if (!name) {
        this.error({
          message: '请填写思维导图名称'
        })
        return
      }
      graphIframe.window._saveXml()
    },
    // 导出图片
    saveImage() {
      const { info: { name } } = this

      if (!name) {
        this.error({
          message: '请填写思维导图名称'
        })
        return
      }
      graphIframe.window._saveImage()
    },
    // 保存思维导图信息
    saveXml(content) {
      const { parentId = '' } = this.$route.query
      const { info } = this
      const api = info.id ? 'editXml' : 'createXml'

      this.$api.cases[api]({ ...info, content, parentId }).then(() => {
        this.success({
          message: '保存成功'
        })
        window.opener.postMessage({
          type: 'refresh'
        }, window.location.origin)
      })
    },
    // 保存思维导图图片
    saveXmlImage(params) {
      console.info(params, 52)
    }
  },
  created() {
    // window._save
    window._load = this.getXmlInfo
    window._save = this.saveXml
    window._saveImage = this.saveXmlImage
  }
};
</script>

<style lang="scss" scoped>
  .graph-container {
    width: 100%;
    .graph-hd {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 0 32px;
      background: #fff;
      > input {
        width: 600px;
        height: 100%;
        font-size: 18px;
      }
    }
    .iframe {
      width: 100%;
      height: 850px;
      margin: 0;
      padding: 0;
      outline: none;
      border: none;
    }
  }
</style>